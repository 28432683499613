// import {environment} from "../../../../src/environments/local";
// let environment = require('../../../../src/environments/local');
// console.log(environment.baseUrl);
console.log(window.location.hostname);
function responseHandler(response) {
  getCartItems(response);
}

function getCartItems (response) {
// CART ITEMS
  var xhr = new XMLHttpRequest();
  var baseUrl = localStorage.getItem('baseUrl');
  // var url = "http://backdrops.localhost/api/website-api/getCartItems";
  var url = baseUrl + 'api/website-api/getCartItems';
  var data = "user_id=" + document.getElementById("user_id").value;
  xhr.open("POST", url, true);
  xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4 && xhr.status == 200) {
      // do something with response
      sendPayload(response, xhr.response);
    }
  };
  xhr.send(data);
  sessionStorage.setItem('data' , data);
}
if (sessionStorage.getItem('discount') !== '') {
function sendPayload(response, cartItems){
  console.log(response);
  if (response.messages.resultCode == 'Error') {
  let error = response.messages.message[0]['text'];
   if (window.ngRouter) {
    window.ngRouter.navigate(['/paymentError'], {
      queryParams: { q: error }
    });
  } else {
    console.log("angular router not aviable ")
    window.location.href = `/paymentError?q=${encodeURIComponent(error)}`;
  }

// window.location.href = `/error?q=${error}`;
//   alert(error);
// console.log(error,"an error has occured new error pushing")
  setTimeout(function(){ location.reload(); }, 1000);
  // alert(error);
  return;
  }
  var baseUrl = localStorage.getItem('baseUrl');
  fetch( baseUrl + 'api/website-api/chargeToken', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify({ 'opaque' : response, 'cart' : JSON.parse(cartItems)  , 'discount' : Number(sessionStorage.getItem('discount')) , 'promo_code' : sessionStorage.getItem('promo_code'),  'promo_code_id' : sessionStorage.getItem('promo_code_id')})
  });
}
} else {
  function sendPayload(response, cartItems){
    console.log(response);
  if (response.messages.resultCode == 'Error') {
  let error = response.messages.message[0]['text'];
  if (window.ngRouter) {
    window.ngRouter.navigate(['/paymentError'], {
      queryParams: { q: error }
    });
  } else {
    console.log("angular router not aviable ")
    window.location.href = `/paymentError?q=${encodeURIComponent(error)}`;
  }
  // alert(error);
  setTimeout(function(){ }, 1000);
  // alert(error);
  return;
  }
    var baseUrl = localStorage.getItem('baseUrl');
    fetch( baseUrl + 'api/website-api/chargeToken', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      },
      body: JSON.stringify({ 'opaque' : response, 'cart' : JSON.parse(cartItems) })
    });
  }
}